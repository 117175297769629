// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.offers-customer-section dl dd {
  float: right;
  margin-left: 10px !important;
}
.offers-customer-section dl dt {
  width: 182px;
  float: right;
  text-align: right;
  padding: 6px 0;
}`, "",{"version":3,"sources":["webpack://./src/scss/components/_offers-customer-section.scss"],"names":[],"mappings":"AAEI;EACE,YAAA;EACA,4BAAA;AADN;AAGI;EACE,YAAA;EACA,YAAA;EACA,iBAAA;EACA,cAAA;AADN","sourcesContent":[".offers-customer-section {\n  dl {\n    dd {\n      float: right;\n      margin-left: 10px !important;      \n    }\n    dt {\n      width: 182px;\n      float: right;\n      text-align: right;\n      padding: 6px 0;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
