// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-sm .search-clear {
  background: #fff;
  display: block;
  font-size: 18px;
  width: 20px;
  z-index: 1;
  height: 23px;
  line-height: 20px;
  /* opacity: 0; */
  border-radius: 15px;
  cursor: pointer;
}

.search-sm input {
  background: none;
  outline: initial !important;
  border: 1px solid #c1c1c1;
  padding: 0.25rem 0.75rem 0.25rem 0.75rem;
  font-size: 0.76rem;
  line-height: 1.3;
  color: #212121;
}
.search-sm:after {
  font-size: 14px;
  border-radius: 10px;
  color: #c1c1c1;
  position: absolute;
  width: 22px;
  right: 4px;
  text-align: center;
  cursor: pointer;
  top: 2px;
}

.search-lg, .search-sm {
  position: relative;
}
.search-lg input, .search-sm input {
  padding-right: 24px;
  padding-left: 35px;
  font-size: 16px;
}
.search-lg .search-icon, .search-sm .search-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translate(0, -50%);
}
.search-lg .search-clear, .search-sm .search-clear {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(0, -50%);
}`, "",{"version":3,"sources":["webpack://./src/scss/components/_search-sm.scss"],"names":[],"mappings":"AAIA;EACE,gBAAA;EACA,cAAA;EACA,eAAA;EACA,WAAA;EACA,UAAA;EACA,YAAA;EACA,iBAAA;EACA,gBAAA;EACA,mBAAA;EACA,eAAA;AAHF;;AAQE;EACE,gBAAA;EACA,2BAAA;EACA,yBAAA;EAEA,wCAAA;EACA,kBAAA;EACA,gBAAA;EACA,cA3BY;AAqBhB;AASE;EAGE,eAAA;EACA,mBAAA;EACA,cAlCc;EAmCd,kBAAA;EACA,WAAA;EACA,UAAA;EACA,kBAAA;EACA,eAAA;EACA,QAAA;AATJ;;AAaA;EACE,kBAAA;AAVF;AAWE;EACE,mBAAA;EACA,kBAAA;EACA,eAAA;AATJ;AAWE;EACE,kBAAA;EACA,UAAA;EACA,QAAA;EACA,6BAAA;AATJ;AAWE;EACE,kBAAA;EACA,WAAA;EACA,QAAA;EACA,6BAAA;AATJ","sourcesContent":["$primary-color: #212121;\n$secondary-color: #c1c1c1;\n\n// search\n.search-sm .search-clear {\n  background: #fff;\n  display: block;\n  font-size: 18px;\n  width: 20px;\n  z-index: 1;\n  height: 23px;\n  line-height: 20px;\n  /* opacity: 0; */\n  border-radius: 15px;\n  cursor: pointer;\n}\n\n.search-sm {\n\n  input {\n    background: none;\n    outline: initial !important;\n    border: 1px solid $secondary-color;\n    // border-radius: 15px;\n    padding: 0.25rem 0.75rem 0.25rem 0.75rem;\n    font-size: 0.76rem;\n    line-height: 1.3;\n    color: $primary-color;\n  }\n\n  &:after {\n    // font-family: \"simple-line-icons\";\n    // content: \"\\e090\";\n    font-size: 14px;\n    border-radius: 10px;\n    color: $secondary-color;\n    position: absolute;\n    width: 22px;\n    right: 4px;\n    text-align: center;\n    cursor: pointer;\n    top: 2px;\n  }\n}\n\n.search-lg, .search-sm {\n  position: relative;\n  input {\n    padding-right: 24px;\n    padding-left: 35px;\n    font-size: 16px;\n  }\n  .search-icon {\n    position: absolute;\n    left: 10px;\n    top: 50%;\n    transform: translate(0, -50%);\n  }\n  .search-clear {\n    position: absolute;\n    right: 10px;\n    top: 50%;\n    transform: translate(0, -50%);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
