import dayjs from "dayjs"
// import utc from "dayjs/plugin/utc"
import relativeTime from "dayjs/plugin/relativeTime"
import React, { useCallback } from "react"
import { Spinner } from "reactstrap"
import Toastify from "toastify-js"
import "toastify-js/src/toastify.css"
// import FormCard from "../components/cards/FormCard"
import "./_toastify.scss"

// dayjs.extend(utc)
dayjs.extend(relativeTime)

export const emptyObject = {}
export const emptyArray = []
export const identityFn = x => x

// Replacing Hi there!
export const openToast = ({ text = "Something went wrong!", type }) => {
  Toastify({
    text,
    duration: 4000,
    close: true,
    gravity: "top", // `top` or `bottom`
    position: "right", // `left`, `center` or `right`
    stopOnFocus: true, // Prevents dismissing of toast on hover
    className: type === "success" ? "toast-success" : "toast-error"
  }).showToast()
}

export function errorWhileLoading() {
  // console.log("Error")
  openToast({
    text: "Error while loading. Please try again.",
    type: "error"
  })
}

export const Loader = ({color}) => <Spinner color={color ? color : "primary"} />
export const CenterLoader = () => <div className="h-400 d-flex align-items-center justify-content-center"><Loader /></div>
// export const FormCardLoader = ({ message }) => (
//   <FormCard>
//     {message ? <strong>{message}</strong> : <Spinner color="primary" />}
//   </FormCard>
// )

const messages = {
  successCreate: "Success", //Successfully created
  successDelete: "Successfully deleted",
  successEdit: "Successfully edited",
  error500: "Something went wrong. Please try again later.", // 500 Internal Server Error
  wrong: "Something went wrong. Please try again later."
}

export const showError = error => {
  if (error?.response?.status === 500) {
    return openToast({
      text: messages.error500,
      type: "error"
    })
  } else {
    console.log('error', error?.response?.data?.error?.message)
    return openToast({
      text:
        error && error?.response?.data?.error?.message
          ? error.response.data.error.message
          : messages.wrong,
      type: "error"
    })
  }
}

export const showSuccess = (data, message) =>
  openToast({
    text: message,
    type: "success"
  })
export const showSuccessEdit = data =>
  openToast({
    text: showMessage("successEdit"),
    type: "success"
  })
export const showSuccessCreate = data =>
  openToast({
    text: showMessage("successCreate"),
    type: "success"
  })
export const showSuccessDelete = data =>
  openToast({
    text: showMessage("successDelete"),
    type: "success"
  })
export const showSuccessDrag = data =>
  openToast({
    text: "Successfully sorted",
    type: "success"
  })
export const showSuccessImport = data =>
  openToast({
    text: "Successfully Imported",
    type: "success"
  })
export const showMessage = message => messages[message]

export const editPhaseSteps = (totalPhase, editPhase) => {
  let steps = []

  // Outer loop to create parent
  // i < eventItem.editPhase;
  let className = ""
  for (let i = 0; i < totalPhase; i++) {
    if (i < editPhase) className = "active"
    else className = "normal"
    steps.push(
      <span className={`item ${className}`} key={i}>
        <span></span>
      </span>
    )
  }
  return steps
}

export const typeMap = {
  1: "speakers",
  2: "exhibitors",
  3: "sponsors",
  4: "attendees", //The attendees feature allows user to check in to your session, as well as view others who have also checked in. They can then use this feature to connect with other attendees.
  5: "bookmarked", //This feature has been enabled.
  6: "schedule",
  7: "info",
  8: "map",
  9: "about",
  10: "interactive",
  11: "twitter",
  12: "news",
  13: "webview", //single, single
  14: "feed", //This feature has been enabled.
  15: "todo", //The to do list feature allows your users to add items from your guide to their own personal to do list.
  16: "notes", //This feature has been enabled.
  17: "feedback", //need to discuss with Shivani
  18: "chat", //This feature has been enabled.
  19: "video",
  20: "photo",
  21: "hunt-game",
  22: "voting",
  23: "ads",
  24: "business-cards",
  25: "appointment"
}

export const typeDescription = {
  1: "Organizer can add the speakers of the event.",
  2: "Organizer can add the exhibitors of the event.",
  3: "Organizer can add the sponsors of the event.",
  4: "All the users who joins the event will be seen in the Attendees section.",
  5: "In this feature, user can bookmark session, speakers, sponsors, attendees that will be shown on their bookmark screen.",
  6: "Organizer can post the schedule for the event.",
  7: "Organizer can add additional information such as contact person for the event, etc.",
  8: "Organizer can add different map location for the event.",
  9: "Organizer can add more information about the event in the About section",
  10: "Interactive feature",
  11: "Twitter feature",
  12: "News feature",
  13: "Show a website URL.",
  14: "Organizer can publish a post from the builder and user can post from their mobile app.",
  15: "Todo feature",
  16: "Personal note that the user can use for the event.",
  17: "Feedback feature",
  18: "Chat feature",
  19: "Organizer can add the link to the video album.",
  20: "Organizer can add the link to the photo album.",
  21: "Organizer can post challenges in the app that the user can view directly or by scanning the QR.",
  22: "For voting competition, Organizer can post projects and  user can only upvote one project. For Auction feature, user can bid the amount. And higher amount will be shown on top.",
  23: "Banner images will be shown on the bottom of the app.",
  24: "User can scan another user's QR code. And the contact information will be saved to the app.",
  25: "User can set the appointment slots. And another user will be able to book an appointment."
}

export const spaceTypeMap = {
  1: "about",
  2: "partner",
  3: "member",
  4: "video",
  5: "photo"
}

export const spaceTypeDescription = {
  1: "Organizer can add more about the organization.",
  2: "Organizer can add partner information.",
  3: "Organizer can add other teams.",
  4: "Organizer can add the link to the photo album.",
  5: "Organizer can add the link to the video album.",
}

export const dayjsFormat = (date, format) => {
  return dayjs(date).format(format)
}

// export const dayjsStartEnd = (startDate, endDate) => {
//   const startDateTime = dayjsFormat(startDate, DATE_FORMAT)
//   const endDateTime = dayjsFormat(endDate, DATE_FORMAT)

//   const endTimeOnly = dayjsFormat(startDate, TIME_ONLY_FORMAT)

//   if (startDateTime === endDateTime) return `${startDateTime} - ${endTimeOnly}`

//   return `${startDateTime} - ${endDateTime}`
// }

export const dayjsStartEnd = (startDate, endDate) => {
  /*
   * 10 am -  6 pm, 30 April, 2020
   * 2 pm 30 Apr - 4 pm 31 Apr, 2020
   * 10 am 29 Dec 2020 - 6 pm 1 Jan 2021
   */
  // 30 April 2020

  const startTimeOnly = dayjsFormat(startDate, "hh:mm a")
  const endTimeOnly = dayjsFormat(endDate, "hh:mm a")

  const startDateOnly = dayjsFormat(startDate, "DD MMM YYYY")
  const endDateOnly = dayjsFormat(endDate, "DD MMM YYYY")

  // 10 am -  6 pm, 30 April, 2020
  if (startDateOnly === endDateOnly) {
    return `${startTimeOnly} - ${endTimeOnly}, ${startDateOnly}`
  }

  const startYearOnly = dayjsFormat(startDate, "YYYY")
  const endYearOnly = dayjsFormat(endDate, "YYYY")

  // 2 pm 30 Apr - 4 pm 31 Apr, 2020
  if (startYearOnly === endYearOnly) {
    const startWithoutYear = dayjsFormat(startDate, "hh:mm a DD MMM")
    const endWithoutYear = dayjsFormat(endDate, "hh:mm a DD MMM")
    return `${startWithoutYear} - ${endWithoutYear}, ${startYearOnly}`
  }

  const startDateFormat = dayjsFormat(startDate, "hh:mm a DD MMM YYYY")
  const endDateFormat = dayjsFormat(endDate, "hh:mm a DD MMM YYYY")

  return `${startDateFormat} - ${endDateFormat}`
}

export const dayjsStartEndTime = (startDate, endDate) => {
  // console.log(startDate, endDate)
  const startTimeOnly = dayjsFormat(startDate, "hh:mm a")
  const endTimeOnly = dayjsFormat(endDate, "hh:mm a")

  const startDateOnly = dayjsFormat(startDate, "DD MMM YYYY")
  const endDateOnly = dayjsFormat(endDate, "DD MMM YYYY")

  // 10 am -  6 pm, 30 April, 2020
  if (startDateOnly === endDateOnly) {
    return `${startTimeOnly} - ${endTimeOnly}, ${startDateOnly}`
  }

  return null
}

export const dayjsFrom = pastDate => {
  return dayjs().from(dayjs(pastDate), true)
}

export function replacePairsOfSymbols(str) {
  if (str) {
    return str
      .replace(/(?:\*)([^*]*)(?:\*)/gm, "<strong>$1</strong>")
      .replace(/(?:_)([^_]*)(?:_)/gm, "<i>$1</i>")
      .replace(/(?:~)([^~]*)(?:~)/gm, "<strike>$1</strike>")
      .replace(/(?:```)([^```]*)(?:```)/gm, "<tt>$1</tt>")
      .replaceAll("\\n", "<br />");
  } else {
    return str;
  }
}
// TODO: how to ... function
// export const calculateEventDaysCount = (startDate, endDate) => {
//   const endDateDayjs = endDate && dayjs(endDate)
//   const startDateDayjs = startDate && dayjs(startDate)
//   const totalDaysCount = endDateDayjs && startDateDayjs && endDateDayjs.diff(startDateDayjs, 'day')
  
//   let eventDaysCount = []
//   for (var i = 0; i <= totalDaysCount; i++) {
//     eventDaysCount.push(i + 1);
//   }

//   return eventDaysCount   
// }

export const findRemovedArr = (oldArr, remainingArr) => {
  // console.log('oldArr', oldArr)
  // make a Set to hold values from namesToDeleteArr
  const namesToDeleteSet = new Set(remainingArr);

  // use filter() method
  // to filter only those elements
  // that need not to be deleted from the array
  const removeTags = oldArr?.filter((name) => {
    // return those elements not in the namesToDeleteSet
    return !namesToDeleteSet.has(name);
  });

  return removeTags
}

// TODO: for formsV2 > view individual response
export function getObjKeysByValue(object, value) {
    let keyArr = []
    let objectValue = '';
    for (let prop in object) {
        if (object.hasOwnProperty(prop)) {
            objectValue = String(object[prop]) // string for includes function
            // if (objectValue === value) {
            if(objectValue.includes(value)) {
                // return prop;
                keyArr.push(prop)
            }
        }
    }
    return keyArr
}