import React, { Component, Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import { CenterLoader } from './utils/common'

const loading = (
  // <div className="pt-3 text-center">
  //   <div className="sk-spinner sk-spinner-pulse"></div>
  // </div>
  <CenterLoader />
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route exact path="/login" name="Login Page" element={<Login />} />
            <Route exact path="/register" name="Register Page" element={<Register />} />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            {/* <Route exact path="/login" name="Login Page" element={!getToken() ? <Login /> : <Navigate to={"/dashboard"} replace />} />
            <Route exact path="/register" name="Register Page" element={!getToken() ? <Register /> : <Navigate to={"/dashboard"} replace />} />
            <Route exact path="/404" name="Page 404" element={!getToken() ? <Page404 /> : <Navigate to={"/dashboard"} replace />} />
            <Route exact path="/500" name="Page 500" element={!getToken() ? <Page500 /> : <Navigate to={"/dashboard"} replace />} /> */}

            <Route path="*" name="Home" element={<DefaultLayout />} />
            {/* <Route path="*" name="Home" element={getToken() ? <DefaultLayout /> : <Navigate to={"login"} replace />} /> */}
          </Routes>
        </Suspense>
      </BrowserRouter>
    )
  }
}

export default App
