// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toastify {
  padding: 25px;
  border-radius: 0.75rem;
}

.toastify-right {
  top: 90px !important;
}
@media (min-width: 768px) {
  .toastify-right {
    top: 100px !important;
  }
}
@media (min-width: 1200px) {
  .toastify-right {
    top: 110px !important;
  }
}
@media (min-width: 1440px) {
  .toastify-right {
    top: 120px !important;
  }
}

.toast-close {
  margin-left: 10px;
}

.toast-success {
  background: var(--success);
}

.toast-error {
  font-size: inherit;
}`, "",{"version":3,"sources":["webpack://./src/utils/_toastify.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;AACF;;AACA;EACE,oBAAA;AAEF;AADE;EAFF;IAGI,qBAAA;EAIF;AACF;AAHE;EALF;IAMI,qBAAA;EAMF;AACF;AALE;EARF;IASI,qBAAA;EAQF;AACF;;AANA;EACE,iBAAA;AASF;;AAPA;EACE,0BAAA;AAUF;;AARA;EAEE,kBAAA;AAUF","sourcesContent":[".toastify {\n  padding: 25px;\n  border-radius: 0.75rem;\n}\n.toastify-right {\n  top: 90px !important;\n  @media (min-width: 768px) {\n    top: 100px !important;\n  }\n  @media (min-width: 1200px) {\n    top: 110px !important;\n  }\n  @media (min-width: 1440px) {\n    top: 120px !important;\n  }\n}\n.toast-close {\n  margin-left: 10px;\n}\n.toast-success {\n  background: var(--success);\n}\n.toast-error {\n  // background: red !important;\n  font-size: inherit;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
